import Select from 'react-select';
import { useEffect, useState } from 'react'
import './App.css';

const selectStyle = {
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    fontFamily: 'RoadRadio'
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    cursor: 'pointer',
    border: 'none',
    background: 'var(--input-background-color)',
    borderRadius: 'var(--border-radius)',
    fontFamily: 'RoadRadioThin',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    cursor: 'pointer',
    border: 'none',
    background: 'var(--input-background-color)',
    borderRadius: 'var(--border-radius)',
    fontFamily: 'RoadRadioThin',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    height: 'var(--height)',
    cursor: 'pointer',
    border: 'none',
    background: 'var(--input-background-color)',
    borderRadius: 'var(--border-radius)',
    width: '100%',
    fontFamily: 'RoadRadioThin',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    cursor: 'pointer',
    border: 'none',
    background: state.isSelected ? '#E4E4E4' : 'var(--input-background-color)',
    color: '#000000',
    height: 'var(--height)',
    fontFamily: 'RoadRadioThin',
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    display: 'none',
    fontFamily: 'RoadRadioThin',
  })
}


const App = () => {
  let tg = window.Telegram.WebApp;

  const [requestCountFrom, setRequestCountFrom] = useState(0);
  const [requestCountTo, setRequestCountTo] = useState(0);
  const [percentageDifference, setPercentageDifference] = useState(0);
  const [simpleDifference, setSimpleDifference] = useState(0);
  const [differenceType, setDifferenceType] = useState("simple");
  const [period, setPeriod] = useState("week");
  const [intervalTrigger, setIntervalTrigger] = useState(3600);
  const [maxCardCount, setMaxCardCount] = useState(0);
  const [isCheckMaxCardCount, setIsCheckMaxCardCount] = useState(false);

  const method = 'https://api.slezdom.ru/sync'
  // const method = 'http://127.0.0.1:8000/sync'

  const periodOptions = [
    { value: "week", label: 'неделя' },
    { value: "month", label: 'месяц' },
    { value: "3month", label: '3 месяца' },
  ]

  const intervalTriggerOptions = [
    { value: 1800, label: '30мин' },
    { value: 3600, label: '1ч' },
    { value: 7200, label: '2ч' },
    { value: 10800, label: '3ч' },
    { value: 14400, label: '4ч' },
    { value: 18000, label: '5ч' },
    { value: 21600, label: '6ч' },
    { value: 25200, label: '7ч' },
    { value: 28800, label: '8ч' },
    { value: 32400, label: '9ч' },
    { value: 36000, label: '10ч' },
    { value: 39600, label: '11ч' },
    { value: 43200, label: '12ч' },
    { value: 46800, label: '13ч' },
    { value: 50400, label: '14ч' },
    { value: 54000, label: '15ч' },
    { value: 57600, label: '16ч' },
    { value: 61200, label: '17ч' },
    { value: 64800, label: '18ч' },
    { value: 68400, label: '19ч' },
    { value: 72000, label: '20ч' },
    { value: 75600, label: '21ч' },
    { value: 79200, label: '22ч' },
    { value: 82800, label: '23ч' },
    { value: 86400, label: '24ч' }
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(method);
        const data = await response.json();
        if (data && Object.keys(data).length > 0) {
          setRequestCountFrom(data.request_count_from);
          setRequestCountTo(data.request_count_to);
          setPeriod(data.period);
          setDifferenceType(data.difference_type);
          setIntervalTrigger(data.interval);
          setPercentageDifference(data.percentage_difference);
          setSimpleDifference(data.simple_difference);
          setMaxCardCount(data.max_card_count);
          setIsCheckMaxCardCount(data.is_check_max_card_count);
        }
      } catch (error) {
        console.error('Ошибка при получении конфигурации:', error);
      }
      tg.expand()
    }
  
    fetchData();
  }, []);
  
  const handleSubmit = async () => {
    const data = {
      'request_count_from': Number(requestCountFrom),
      'request_count_to': Number(requestCountTo),
      'period': period,
      'difference_type': differenceType,
      'percentage_difference': Number(percentageDifference),
      'simple_difference': Number(simpleDifference),
      'interval': Number(intervalTrigger),
      'max_card_count': Number(maxCardCount),
      'is_check_max_card_count': isCheckMaxCardCount
    };
  
    try {
      const response = await fetch(method, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      console.log(result);
      tg.close()
    } catch (error) {
      console.error(error);
    }
  };

  const setPeriodHandle = (e) => {
    setPeriod(e.value)
  }

  return (
    <div>
      <div className='section'>
        <div className='row row-divided'>
          <div className='row-title row-subtitle-h2'>уровень</div>
        </div>
        <div className='row'>
          <div className='row-row'>
            <input 
              type='number' 
              className='input-field-input text-align-center'
              value={requestCountFrom}
              onChange={e => setRequestCountFrom(e.target.value)}
            />
            <div className='separator'> — </div>
            <input
              type='number' 
              className='input-field-input text-align-center'
              value={requestCountTo}
              onChange={e => setRequestCountTo(e.target.value)}
            />
          </div>
        </div>
        <div className='row row-divided'>
          <div className='row-title row-subtitle-h2'>период</div>
        </div>
        <div className='row'>
          <Select
            isDisabled={true}
            isSearchable={false}
            options={periodOptions}
            value={periodOptions.find((o) => o.value === period)}
            onChange={setPeriodHandle}
            placeholder={'период'}
            classNames={{
              control: (state) => 'input-field-input',
            }}
            styles={selectStyle}
          />
        </div>
        <div className='row row-divided'>
          <div className='row-title row-subtitle-h2'>условия</div>
        </div>
        <div className='row'>
          <div className='input-wrapper'>
            <div className='row-row'>
              <div className='row-row'>
                <input 
                  type='radio' 
                  name='condition'
                  className='input-field-radio'
                  value={'percentage'}
                  checked={differenceType === 'percentage'}
                  onChange={e => setDifferenceType(e.target.value)}
                />
                <label className='row-subtitle row-subtitle-small'>в % к стартовому числу</label>
              </div>
              <input 
                type='number' 
                className='input-field-input text-align-center input-field-input-small row-right' 
                value={percentageDifference}
                onChange={e => setPercentageDifference(e.target.value)}
              />
            </div>
            <div className='row-row'>
              <div className='row-row'>
                <input
                   type='radio' 
                   name='condition'
                   className='input-field-radio'
                   value={'simple'}
                   checked={differenceType === 'simple'}
                   onChange={e => setDifferenceType(e.target.value)}
                />
                <label className='row-subtitle row-subtitle-small'>рост от стартового числа на:</label>
              </div>
              <input 
                type='number' 
                className='input-field-input text-align-center input-field-input-small row-right' 
                value={simpleDifference}
                onChange={e => setSimpleDifference(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='row row-divided'>
          <div className='row-title row-subtitle-h2'>обновление данных</div>
        </div>
        <div className='row'>
          <Select
            isSearchable={false}
            value={intervalTriggerOptions.find((o) => o.value === intervalTrigger)}
            options={intervalTriggerOptions}
            onChange={e => setIntervalTrigger(e.value)}
            placeholder={'обновление данных'}
            classNames={{
              control: (state) => 'input-field-input',
            }}
            styles={selectStyle}
          />
        </div>
        <div className='row row-divided'>
          <div className='row-title row-subtitle-h2'>//</div>
        </div>
        <div className='row'>
          <div className='input-wrapper'>
            <div className='row-row'>
              <div className='row-row'>
                <input
                   type='checkbox' 
                   name='condition'
                   className='input-field-radio'
                   checked={isCheckMaxCardCount}
                   onChange={e => setIsCheckMaxCardCount(e.target.checked)}
                />
                <label className='row-subtitle row-subtitle-small'>количество карточек по запросу не более:</label>
              </div>
              <input 
                type='number' 
                className='input-field-input text-align-center input-field-input-small row-right' 
                value={maxCardCount}
                onChange={e => setMaxCardCount(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='row row-divided-top'>
          <div className='row-row row-grid'>
            <button className='input-field-input button input-field-input-small row-right' onClick={handleSubmit}>применить</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;